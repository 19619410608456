import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "media"]

  chooseFile() {
    this.inputTarget.click()
  }

  previewNewFile() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const media = this.mediaTarget;
      let reader = new FileReader();

      reader.onload = function (e) {
        media.setAttribute('src', e.target.result);
      }

      reader.readAsDataURL(this.inputTarget.files[0]);
    }
  }
}
