import Trix from 'trix';

$(document).on("trix-initialize", function(event) {
  const { toolbarElement } = event.target
  const { editor } = event.target
  const fileTools = toolbarElement.querySelector("[data-trix-button-group=file-tools]")
  
  if (toolbarElement.querySelector(".trix-dialog__embed-container") == null) {
    insertEmbedFunctionality(editor, toolbarElement)
  }
  
  if (toolbarElement.querySelector("[data-trix-action='x-embed']") == null) {
    fileTools.insertAdjacentHTML("afterbegin", `
      <button type="button" class="trix-button trix-button--icon trix-button--icon-embed" data-trix-action="x-embed" title="Embed" tabindex="-1"></button>
    `)

    toolbarElement.querySelector(".trix-dialogs").insertAdjacentHTML("beforeend", `
      <div class="trix-dialog trix-dialog--embed" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
        <div class="trix-dialog__link-fields">
          <textarea name="embed" class="trix-input trix-input--dialog" placeholder="Enter embed code..." aria-label="embed" rows="3"></textarea>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="Embed" data-trix-dialog-submit--custom-embed>
          </div>
        </div>
      </div>
    `)

    toolbarElement.querySelector("[data-trix-dialog-submit--custom-embed]").addEventListener('click', function () {
      $.ajax({
        url: "/embeds",
        type: "post",
        data: { "content": toolbarElement.querySelector("textarea[name=embed]").value },
        success: function (data) { addEmbedPreview(editor, toolbarElement, data) },
        error: function (data) { console.log("Embed Error") }
      })
    });
  }
});

$(document).on("trix-action-invoke", function (e) {
  event = e.originalEvent

  if (event.actionName == "x-embed") {
    const { toolbarElement } = event.target
    let button = toolbarElement.querySelector(".trix-button--icon-embed")
    let dialog = toolbarElement.querySelector(".trix-dialog--embed")
    if (button.classList.contains("trix-active")) {
      button.classList.remove("trix-active")
      dialog.classList.remove("trix-active")
      dialog.removeAttribute("data-trix-active")
    } else {
      button.classList.add("trix-active")
      dialog.classList.add("trix-active")
      dialog.setAttribute("data-trix-active", "")
      toolbarElement.querySelector("textarea[name=embed]").focus()
    }
  }
});

function insertEmbedFunctionality(editor, toolbarElement) {
  const linkDialog = toolbarElement.querySelector(".trix-dialog--link")
  const linkInput = toolbarElement.querySelector("input.trix-input--dialog")
  const embedContainer =
  `<div class="trix-dialog__embed-container">
    <span> Would you like to embed media from this site? </span>
    <input type="button" class="trix-button trix-button--dialog" value="Embed" data-trix-dialog-submit--video>
  </div>`;

  linkDialog.insertAdjacentHTML("beforeend", embedContainer);

  linkInput.addEventListener('input', function() {
    $.ajax({
      url: "/embeds/new?link=" + linkInput.value,
      type: "get",
      success: function(data) { toolbarElement.querySelector(".trix-dialog__embed-container").classList.add('active') },
      error: function(data) { toolbarElement.querySelector(".trix-dialog__embed-container").classList.remove('active') }
    })
  })

  toolbarElement.querySelector("[data-trix-dialog-submit--video]").addEventListener('click', function() {
    $.ajax({
      url: "/embeds",
      type: "post",
      data: { "link": linkInput.value },
      success: function(data) { addEmbedPreview(editor, toolbarElement, data) },
      error: function(data) { toolbarElement.querySelector(".trix-dialog__embed-container").classList.remove('active') }
    })
  });
}

function addEmbedPreview(editor, toolbarElement, data) {
  const attachmentVideo = createTrixAttachmentFromEmbed(data)
  toolbarElement.querySelector(".trix-dialog__embed-container").classList.remove('active')
  hideTrixToolbarDialogs(toolbarElement)
  editor.insertAttachment(attachmentVideo)
  editor.selectionManager.unlock()
}

function hideTrixToolbarDialogs(toolbarElement) {
  const dialog = toolbarElement.querySelector(".trix-dialog")
  const dialogInput = dialog.querySelector(".trix-input")
  dialog.querySelector(".trix-dialog__embed-container").classList.remove('active')
  dialog.removeAttribute("data-trix-active")
  dialog.classList.remove("trix-active")
  dialogInput.setAttribute("disabled", "disabled")
  dialogInput.removeAttribute("data-trix-validate")
  dialogInput.classList.remove("trix-validate")

  toolbarElement.querySelector(".trix-button--icon-embed").classList.remove('trix-active')
  toolbarElement.querySelector("textarea[name=embed]").value = ""
}

function createTrixAttachmentFromEmbed(data) {
  let attachment = new Trix.Attachment({
    sgid: data["attachable_sgid"],
    content: data["content"]
  })
  return attachment
}

$(document).on("trix-change", function(event) {  
  const trixEditor = event.target
  const hasPendingAttachments = trixEditor.editor.getDocument().getAttachments().map(a => a.isPending()).includes(true)

  if (hasPendingAttachments) {
    const uploadingButtonText = "Uploading files...";
    const submitButton = $("input[type='submit']");
    const originalValue = submitButton.val();

    if (originalValue != uploadingButtonText) {
      submitButton.addClass("disabled");
      submitButton.data("original-text-value", originalValue);
      submitButton.val(uploadingButtonText);
    }
  } else {
    const submitButtonDataOriginalValue = $("input[type='submit']").data("original-text-value")

    if (submitButtonDataOriginalValue) {
      const submitButton = $("input[type='submit']");
      submitButton.val(submitButtonDataOriginalValue);
      submitButton.removeClass("disabled");
    }
  }
});
