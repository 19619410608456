import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "themeDiv", "backgroundColor", "textColor" ]

  selectTheme(event) {
    this.setActive(event)

    this.backgroundColorTarget.value = event.currentTarget.dataset.backgroundColor
    this.textColorTarget.value = event.currentTarget.dataset.textColor
  }

  setActive(event) {
    this.themeDivTargets.forEach((el) => { el.classList.remove("active") })

    event.currentTarget.classList.add("active")
  }
}
