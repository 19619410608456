document.addEventListener('keydown', function(event) {
  if (event.target == document.body && event.code == 'ArrowDown') {
    event.preventDefault();
    const submission = document.querySelector(".submission_preview.active");
    const nextSubmission = (submission && submission.nextSibling) || document.querySelector(".submission_preview");
    nextSubmission && nextSubmission.querySelector("a").click();
  }
});

document.addEventListener('keydown', function(event) {
  if (event.target == document.body && event.code == 'ArrowUp') {
    event.preventDefault();
    const submission = document.querySelector(".submission_preview.active");
    const prevSubmission = (submission && submission.previousSibling) || document.querySelector(".submission_preview:last-child");
    prevSubmission && prevSubmission.querySelector("a").click();
  }
});

document.addEventListener('keydown', function(event) {
  if (event.target == document.body && event.code == 'KeyS') {
    event.preventDefault();
    const button = document.querySelector(".js-mark-as-spam");
    button && button.click();
  }
});

document.addEventListener('keydown', function(event) {
  if (event.target == document.body && event.code == 'KeyH') {
    event.preventDefault();
    const button = document.querySelector(".js-mark-as-ham");
    button && button.click();
  }
});