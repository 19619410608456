import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {

  change(selectedDates, dateStr, instance) {
    if (instance.input.hasAttribute("data-date-query")) {
      let url = new URL(location.href);
      let new_url = url.origin + url.pathname + "?date=" + dateStr;
      Turbolinks.visit(new_url)
    }
  }
}
