import Tagify from "@yaireo/tagify"

document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll('tags.tagify').forEach((elem) => { elem.remove() })
  
  document.querySelectorAll('.tagifyable').forEach((elem) => {
    const whitelist = JSON.parse( elem.getAttribute("data-autocomplete") )
    
    new Tagify(elem, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      maxTags: 5,
      whitelist : whitelist,
        dropdown : {
          enabled       : 2,
          maxItems      : 2,
          position      : "text"
        }
    })
  })
  
  document.querySelectorAll('.tagifyable-emails').forEach((elem) => {
    new Tagify(elem, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      delimiters: ",| |\n",
      pattern: /^\S+@\S+$/
    })
  })
})