import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Flatpickr from './controllers/flatpickr_controller'

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

require("trix")
require("@rails/actiontext")

require("custom/trix")
require("custom/places")
require("custom/tagify")
require("custom/data_links")
require("custom/keyboard_shortcuts")

application.register('flatpickr', Flatpickr)
