import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggle", "content", "section" ]

  initialize() {
    this.toggle()
  }

  toggle() {
    if (this.on) {
      this.contentTarget.classList.remove("hidden")
    } else {
      this.contentTarget.classList.add("hidden")
      this.clearSection()
    }
  }

  clearSection() {
    if (this.hasSectionTarget) {
      this.sectionTarget.querySelectorAll('input.string').forEach((elem) => { 
        elem.value = "" 
        elem.dispatchEvent(new Event('change'))
      })
      this.sectionTarget.querySelectorAll('textarea').forEach((elem) => { 
        elem.value = "" 
        elem.dispatchEvent(new Event('change')) 
      })
    }
  }

  get on() {
    return this.toggleTarget.checked
  }
}
