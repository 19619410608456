$(document).on("turbolinks:load", function() {
  var placeSearch, placeAutocomplete;

  var componentForm = {
    locality: { id: "event_city", name: 'long_name' },
    country: { id: "event_country", name: 'long_name' },
    postal_code: { id: "event_zip_code", name: 'short_name' },
    administrative_area_level_1: { id: "event_state_or_region", name: 'long_name' }
  };

  function initAutocomplete() {
    document.querySelector('#placeAutocomplete').removeEventListener('click', initAutocomplete);
    const input = document.querySelector('#placeAutocomplete');

    placeAutocomplete = new google.maps.places.Autocomplete(input);
    placeAutocomplete.addListener('place_changed', fillInAddress);

    // Prevent form submit on enter when selecting option from autocomplete
    google.maps.event.addDomListener(input, 'keydown', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  }

  function fillInAddress() {
    var place = placeAutocomplete.getPlace();

    document.getElementById("event_place_google_id").value = place["id"];
    document.getElementById("event_place_name").value = place["name"];
    document.getElementById("event_formatted_address").value = place["formatted_address"];

    fillInPlaceGeometry(place);

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      var component = componentForm[addressType];
      var input = component && document.getElementById(component["id"]);

      if (componentForm[addressType] && input) {
        var val = place.address_components[i][componentForm[addressType]["name"]];
        input.value = val;
      }
    }
  }

  function fillInPlaceGeometry(place) {
    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();
    document.getElementById('event_latitude').value = lat;
    document.getElementById('event_longitude').value = lng;
    displayFairAddress();
  }

  if ($('#placeAutocomplete').length) {
    document.querySelector('#placeAutocomplete').addEventListener('click', initAutocomplete);
  }
});

$(document).on("turbolinks:load", function() {
  var lat = document.getElementById('event_latitude');
  if (lat && lat.value.length) {
    displayFairAddress();
  }
})

$(document).on("turbolinks:load", function() {
  const placesAutocomplete = document.getElementById("placeAutocomplete")
  
  if (placesAutocomplete) {
    placesAutocomplete.addEventListener("change", function () {
      if (this.value === "") {
        document.querySelector(".location-details").classList.add("hidden")
      }
    })
  }
})

function displayFairAddress() {
  displayStaticMap()
  displayAddressDetails()
  $(".location-details").removeClass("hidden");
}

function displayAddressDetails() {
  document.getElementById("place_name").textContent = document.getElementById("event_place_name").value
  document.getElementById("place_formatted_address").textContent = document.getElementById("event_formatted_address").value
}

function displayStaticMap() {
  var lat = document.getElementById('event_latitude').value;
  var lng = document.getElementById('event_longitude').value;
  var key = $(".static-map-wrapper").data("key");
  var mapStyle = "style=feature%3Aall%7Celement%3Alabels%7Cvisibility%3Aoff&style=feature%3Aadministrative%7Celement%3Ageometry.fill%7Ccolor%3A0xf5f5f5&style=feature%3Aadministrative%7Celement%3Alabels.text%7Clightness%3A15&style=feature%3Aadministrative.locality%7Celement%3Alabels.text.fill%7Clightness%3A-11&style=feature%3Aadministrative.neighborhood%7Celement%3Alabels%7Cvisibility%3Aon&style=feature%3Aadministrative.land_parcel%7Celement%3Aall%7Cvisibility%3Asimplified&style=feature%3Alandscape%7Celement%3Ageometry.fill%7Ccolor%3A0xf5f5f5%7Clightness%3A-1&style=feature%3Alandscape.natural.terrain%7Celement%3Ageometry.fill%7Cvisibility%3Aoff&style=feature%3Apoi%7Celement%3Alabels%7Cvisibility%3Aoff&style=feature%3Apoi.business%7Celement%3Aall%7Cvisibility%3Aoff&style=feature%3Aroad%7Celement%3Alabels%7Clightness%3A20%7Cvisibility%3Aoff&style=feature%3Aroad.highway%7Celement%3Ageometry%7Cvisibility%3Asimplified%7Ccolor%3A0xffffff%7Clightness%3A15&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry%7Cvisibility%3Asimplified%7Chue%3A0xff6500%7Clightness%3A0&style=feature%3Aroad.highway.controlled_access%7Celement%3Alabels%7Cvisibility%3Aoff&style=feature%3Aroad.arterial%7Celement%3Ageometry%7Cvisibility%3Asimplified%7Ccolor%3A0xffffff&style=feature%3Aroad.arterial%7Celement%3Alabels%7Cvisibility%3Aon&style=feature%3Aroad.local%7Celement%3Ageometry%7Cvisibility%3Aon&style=feature%3Aroad.local%7Celement%3Ageometry.stroke%7Cvisibility%3Aon%7Ccolor%3A0xffffff%7Clightness%3A0&style=feature%3Aroad.local%7Celement%3Alabels%7Cvisibility%3Aoff%7Clightness%3A28&style=feature%3Atransit%7Celement%3Aall%7Cvisibility%3Aoff%7Clightness%3A15&style=feature%3Awater%7Celement%3Aall%7Chue%3A0x007bff%7Csaturation%3A30%7Clightness%3A49&style=feature%3Awater%7Celement%3Ageometry%7Ccolor%3A0x39c2c9%7Csaturation%3A-20%7Cgamma%3A1.00%7Clightness%3A46&style=feature%3Awater%7Celement%3Alabels%7Cvisibility%3Aoff"

  var src = 'https://maps.googleapis.com/maps/api/staticmap?' + mapStyle + '&markers=scale%3A2%7Cicon%3Ahttps%3A%2F%2Fgoo.gl%2FJZUC3p%7C' + lat + ',' + lng + '&zoom=13&scale=2&size=540x165&key=' + key;

  $(".static-map-wrapper").css('backgroundImage','url('+src+')');
  $(".static-map-wrapper").css('opacity',1);
  document.getElementById("event_map_img_url").value = src;
}
